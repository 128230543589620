/* Browsers which partially support CSS Environment variables (iOS 11.0). */
@supports (padding-top: constant(safe-area-inset-top)) {
  :root {
    --safe-area-inset-top: constant(safe-area-inset-top);
    --safe-area-inset-bottom: constant(safe-area-inset-bottom);
  }
}

/* Browsers which fully support CSS Environment variables (iOS 11.2+). */
@supports (padding-top: env(safe-area-inset-top)) {
  :root {
    --safe-area-inset-top: env(safe-area-inset-top);
    --safe-area-inset-bottom: env(safe-area-inset-bottom);
  }
}

html,
body,
#root {
  height: 100vh;
  -webkit-overflow-scrolling: touch;
}

* {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

input, textarea /*.contenteditable?*/ {
  -webkit-touch-callout: default;
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}

.MuiFormLabel-root {
  overflow: hidden;
  white-space: nowrap;
}
